import React from "react";
import Typist from "react-typist";

import "./App.css";

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <Typist cursor={{ blink: true, hideWhenDone: true }}>
            <code>Henry Pye</code>
            <br />
            <Typist.Delay ms={500} />
            <code>Full Stack Developer</code>
            <br />
            <Typist.Delay ms={500} />
            <code>
              Check out my <a href="https://github.com/Signal-Fire">GitHub</a>
            </code>
          </Typist>
        </p>
      </header>
    </div>
  );
};

export default App;
